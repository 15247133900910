import React from 'react'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import { Box, Container } from '@material-ui/core'
import { H3, BackgroundImage } from '@system'
import PageContainer from '@system/page-container'

const useStyles = makeStyles((theme) => ({
  image: {
    width: '100vw',
    height: '49vw',
    maxWidth: 1920,
    maxHeight: 941,
    backgroundSize: '86% auto',
    backgroundPosition: 'top',
  },
  text: {
    marginTop: '7vw',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0',
    },
    textAlign: 'center',
  },
  backgroundImage: {
    width: '86vw',
    height: '51vw',
    maxHeight: 941,
    backgroundSize: '86% auto',
    backgroundPosition: 'top',
  },
}))

const GlobalPresenceModule = (props) => {
  const classes = useStyles()
  return (
    <SbEditable content={props.blok}>
      <PageContainer>
        <Box
          display="flex"
          justifyContent="center"
          width="100%"
          maxWidth="1920px"
        >
          <BackgroundImage
            className={classes.backgroundImage}
            image={props.blok.image}
          >
            <Container fixed>
              <H3 className={classes.text} color="primary">
                {props.blok.text}
              </H3>
            </Container>
          </BackgroundImage>
        </Box>
      </PageContainer>
    </SbEditable>
  )
}

export default GlobalPresenceModule
